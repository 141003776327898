import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g22'

let batchAddUrl = 'batchAdd'

class G22Service extends RestService {
  batchAdd (g22Models) {
    g22Models.forEach(g22Model => {
      g22Model.generatePrimaryKey()
    })
    return axios.post(utility.getRestFullUrl(this.resourceName, batchAddUrl), g22Models, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g22Service = new G22Service(resourceName)

export default g22Service
