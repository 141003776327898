import utility from '@/common/utility'
import auth from '@/common/auth'

export default class G22Model {
  constructor (props) {
    if (props) {
      this.resourceId = props.g22Guid
      this.g22Guid = props.g22Guid
      this.planStatExaminationGuid = props.planStatExaminationGuid
      this.materialListGuid = props.materialListGuid
      this.num = props.num
      this.firstYearNum = props.firstYearNum
      this.firstYearPercent = props.firstYearPercent
      this.secondYearNum = props.secondYearNum
      this.secondYearPercent = props.secondYearPercent
      this.thirdYearNum = props.thirdYearNum
      this.thirdYearPercent = props.thirdYearPercent
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
      this.tenderGuid = props.tenderGuid
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.g22Guid) this.g22Guid = ''
    if (!this.planStatExaminationGuid) this.planStatExaminationGuid = ''
    if (!this.materialListGuid) this.materialListGuid = ''
    if (!this.num) this.num = 0
    if (!this.firstYearNum) this.firstYearNum = 0
    if (!this.firstYearPercent) this.firstYearPercent = 0
    if (!this.secondYearNum) this.secondYearNum = 0
    if (!this.secondYearPercent) this.secondYearPercent = 0
    if (!this.thirdYearNum) this.thirdYearNum = 0
    if (!this.thirdYearPercent) this.thirdYearPercent = 0
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
    if (!this.tenderGuid) this.tenderGuid = ''
  }

  generatePrimaryKey () {
    this.g22Guid = utility.getUuid()
    this.resourceId = this.g22Guid
  }
}
